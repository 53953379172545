import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo/logo.png";
import { Footer_link1, Footer_link2, Footer_link3, Copyright_link } from "./utils/String";
import { Facebook, Instagram, Twitter, Github, Dribble } from "./utils/Config";
function Footer() {
	return (
		<>
			<footer className="footer-section pt-80">
				<div className="container">
                    <div className="footer-top">
                        <div className="row justify-content-center mb-30-none">
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <Link className="site-logo" to="/">
                                            <img src={Logo} alt="site-logo" />
                                        </Link>
                                    </div>
                                    <p className="logo-text">Welcome to our AI App Elermond Development's Studio! We are a team of experienced and innovative developers dedicated to creating cutting-edge AI-powered applications for businesses and individuals.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <h3 className="title">Contact Info</h3>
                                    <ul className="footer-list">
                                        <li><span>Address:</span> str. Vasile Alecsandri</li>
                                        <li><span>Phone:</span> +37360242452</li>
                                        <li><span>Email:</span> contact@elermond.com</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <h3 className="title">Quick Links</h3>
                                    <ul className="footer-list">
                                        <li>
                                            <Link to="/privacy">
                                                {Footer_link1}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms">
                                                {Footer_link2}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/refund">
                                                {Footer_link3}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-bottom-wrapper">
                            <div className="copyright">
                                Copyright &copy; 2023. All Rights Reserved by <Link to="/">{Copyright_link}</Link>
                            </div>
                        </div>
                    </div>
				</div>
			</footer>
		</>
	);
}

export default Footer;