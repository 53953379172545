export const Header_link1 = "Home";
export const Header_link2 = "About";
export const Header_link3 = "Pricing";
export const Header_btn = "Download App";


export const Banner_title = "Join us in shaping the future of technology.";
export const Banner_para = "Ready to take your productivity to the next level? Join us and download our app today! Our app is packed with powerful features and functionalities designed to streamline your workflows and enhance your productivity.";
export const Banner_btn = "Get Started";


export const ImageOverview_subtitle = "Image Generate";
export const ImageOverview_title = "Easy way to generate images using chatGPT through AIBrain at low cost";
export const ImageOverview_para1 = "Our advanced AI algorithms are designed to generate high-quality images that are tailored to your specific needs. From product images to social media graphics, our AI image generator has got you covered.";
export const ImageOverview_para2 = "And the best part? Our AI image generating services are priced competitively, so you can enjoy beautiful designs without having to break the bank.";


export const TextOverview_subtitle = "Text Generate";
export const TextOverview_title = "Write a composition using chatGPT through AIBrain at a low cost";
export const TextOverview_para1 = "Looking for an app with AI services that won't cost you an arm and a leg? Our app offers affordable AI services that are perfect for businesses and individuals on a budget. Our team of experts has designed the app to provide high-quality AI functionalities at a fraction of the cost of other apps. With our app, you can enjoy features like natural language processing, machine learning, and predictive analytics, all without breaking the bank. Our AI services are designed to help you automate tasks, streamline workflows, and gain valuable insights into your business. And with our affordable pricing, you can experience the benefits of AI without the hefty price tag.";
export const TextOverview_para2 = "So why pay more for AI services when you can get the same quality at a lower cost? Choose our app and start taking advantage of the power of AI today. Try us out now and see the difference for yourself!";


export const About_subtitle = "Know About Us";
export const About_title = "We Tackle Business Issues With Modern Multiple Innovation";
export const About_para = "Welcome to our AI App Elermond Development's Studio! We are a team of experienced and innovative developers dedicated to creating cutting-edge AI-powered applications for businesses and individuals. Our mission is to empower our clients with intelligent and efficient solutions that enhance their productivity and bottom line. At our studio, we believe that AI is the future of technology and that it has the power to revolutionize the way we live and work. With this belief in mind, we are committed to staying at the forefront of AI development and constantly exploring new ways to leverage this technology to create powerful and practical applications. Our team consists of experts in AI, machine learning, data science, and software development. We have worked with clients across a wide range of industries, including healthcare, finance, retail, and more. We have the knowledge and expertise to help you create the perfect AI-powered application for your unique needs. We take a collaborative approach to our work and work closely with our clients to ensure that we fully understand their goals and requirements. We pride ourselves on our ability to create customized solutions that are tailored to each client's unique needs. Whether you are looking to streamline your business processes, automate repetitive tasks, or create a new product that leverages AI, we can help. Contact us today to learn more about how we can help you harness the power of AI to achieve your business goals.";


export const PlanLeft_subtitle = "Pricing Plan";
export const PlanLeft_title = "Connect with AIBrain to use chatGPT at a minimal cost";
export const PlanLeft_para = "";
export const PlanFree_subtitle = "Free";
export const PlanPremium_subtitle = "Premium";
export const Plan_btn = "Get Now";


export const Footer_link1 = "Privacy Policy";
export const Footer_link2 = "Terms & Condition";
export const Footer_link3 = "Refund Policy";
export const Copyright_link = "AIBrain";


export const Logo_text = "AIBrain";